<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src=" userObj.image "
            class="c-avatar-img"
          />
          <span id="dot" class="dot"></span>

        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class=""
      color="light"
    >
      <strong>{{ userObj.name }}</strong>
      <p>
      {{ userObj.email }}
      </p> 
    </CDropdownHeader>
    <CDropdownItem to="/profile">
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem to="/settings">
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>
    <CDropdownItem to="/login" v-if="!isLoggedIn">
      <CIcon name="cil-dollar" /> Login
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click.prevent="logOut">
      <CIcon name="cil-lock-locked"  v-if="isLoggedIn"/> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//import { mapGetters } from 'vuex'
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  computed: {
    ...mapGetters([ 'isLoggedIn', 'userObj' ])
  },
  methods: {
    ...mapActions([ 'logoutUser' ]),
    logOut(){
      this.logoutUser()
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }

  .helo{
    border: 2px solid red;
  }
  #dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-style: solid;
  border-width: 4px;
  border-radius: 100%;
  color: #a1dba1;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>