<template>
  <CFooter :fixed="false">
    <div>
      <a
        href="https://www.onecentral.ng/"
        target="_blank"
        class="font-weight-bold"
        >OneCentral</a
      >
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="#" class="font-weight-bold">Tishlabs Technologies</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
